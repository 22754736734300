<template>
  <div style="max-width: 600px; margin: 100px auto 150px;">
    <textarea v-model="postal" placeholder="Enter delivery name/address/country"/>
    <button @click="pay">Pay with $IMX</button>
    <button>Pay with $GODS</button>
    <button>Pay with $imxETH</button>
  </div>
</template>

<script>
//import { Link } from '@imtbl/imx-sdk';
import {ImmutableXClient} from "@imtbl/imx-sdk";
//import faunadb from "faunadb";
//const q = faunadb.query
export default {
  name: "BuyCard",
  props: ['address'],
  data(){
    return{
      postal: ""
    }
  },
  methods:{
    async pay(){
      const client = await ImmutableXClient.build({publicApiUrl: 'https://api.x.immutable.com/v1'});
      let data = await client.getBalances({user:this.address, owner:this.$tokens['gods']})
      console.log(data.imx.toString())
    }
  },
}
</script>

<style scoped>

</style>